
.ProjectThumbnails {
    width: 856px;
    text-align: left;
    font-size: 0;
}

.ProjectThumbnails__thumbnail {
    width: 214px;
    height: 214px;
    background-size: cover;
    display: inline-block;
}

@media (max-width: 980px) {
    .ProjectThumbnails {
        width: 100%;
    }

    .ProjectThumbnails__thumbnail {
        width: 50%;
        height: 0px;
        padding-bottom: 50%;
    }
}
