.App {
  text-align: center;
}

.AppContainer {
  width: 854px;
  margin: 0px auto;
  position: relative;
}

@media (max-width: 980px) {
  .AppContainer {
    width: calc(100% - 40px - 40px);
    margin: 0 40px;
  }
}
