.AboutIcon {
    position: absolute;
    right: 0px;
    top: -90px;
    width: 43px;
    height: 43px;
}

@media (max-width: 980px) {
    .AboutIcon {
        width: 33px;
        height: 33px;
        top: -45px;
        /* width: 63px;
        height: 63px; */
    }
}
