.Footer {
    padding: 98px 0px;
    font-size: 18px;
    text-align: center;
}

@media (max-width: 980px) {
    .Footer {
        padding: 98px 0px;
        font-size: 20px;
        line-height: 25px;
        /* padding: 196px 0px;
        font-size: 40px;
        line-height: 50px; */
    }
}