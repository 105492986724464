.ProjectPage {
    padding-top: 11px;
    font-size: 0px;
    text-align: center;
}

.ProjectPage__text {
    font-size: 20px;
    line-height: 28px;
    margin: -9px 0px -12px;
    white-space: pre-line;
}

.ProjectPage__text.ProjectPage__text--download {
    color: #989898;
    cursor: pointer;
    display: block;
}

.ProjectPage__socialLink {
    color: #989898;
    cursor: pointer;
}

.ProjectPage__title {
    font-size: 50px;
    line-height: 58px;
    margin: -9px 0px -12px;
    white-space: pre-line;
}

.ProjectPage__image {
    width: 854px;
}

.ProjectPage__video {
    width: 854px;
}

.ProjectPage .ProjectPage__text, .ProjectPage .ProjectPage__title, .ProjectPage .ProjectPage__image, .ProjectPage .ProjectPage__video {
    margin-bottom: 100px;
}

.ProjectPage .ProjectPage__text:last-child, .ProjectPage .ProjectPage__title:last-child, .ProjectPage .ProjectPage__image:last-child, .ProjectPage .ProjectPage__video:last-child {
    margin-bottom: 0px;
}

.ProjectPage .ProjectPage__back {
    font-size: 40px;
    text-decoration: none;
    color: black;
}

.ProjectPage .ProjectPage__back:link, .ProjectPage .ProjectPage__back:focus, .ProjectPage .ProjectPage__back:visited, .ProjectPage .ProjectPage__back:active, .ProjectPage .ProjectPage__back:hover {
    text-decoration: none;
    color: black;
}

.ProjectPage .ProjectPage__text:link, .ProjectPage .ProjectPage__text:focus, .ProjectPage .ProjectPage__text:visited, .ProjectPage .ProjectPage__text:active, .ProjectPage .ProjectPage__text:hover {
    text-decoration: none;
    color: black;
}

.ProjectPage a:link, .ProjectPage a:focus, .ProjectPage a:visited, .ProjectPage a:active, .ProjectPage a:hover {
    text-decoration: none;
}

.ProjectPage .ProjectPage__text.ProjectPage__text--download:link, .ProjectPage .ProjectPage__text.ProjectPage__text--download:focus, .ProjectPage .ProjectPage__text.ProjectPage__text--download:visited, .ProjectPage .ProjectPage__text.ProjectPage__text--download:active, .ProjectPage .ProjectPage__text.ProjectPage__text--download:hover {
    color: #989898;
}

@media (max-width: 980px) {
    .ProjectPage__image {
        width: 100%;
    }    
    .ProjectPage__video {
        width: 100%;
    }    
    .ProjectPage__title {
        width: 100%;
    }    
    .ProjectPage__text {
        width: 100%;
    }
    .ProjectPage .ProjectPage__text, .ProjectPage .ProjectPage__title, .ProjectPage .ProjectPage__image, .ProjectPage .ProjectPage__video {
        margin-bottom: 100px;
    }    

    .ProjectPage__text {
        font-size: 24px;
        line-height: 33px;
        /* margin: -12px 0px -16px; */
    }
    
    .ProjectPage__title {
        font-size: 50px;
        line-height: 55px;
        /* margin: -12px 0px -16px; */
    }

    .ProjectPage {
        padding-top: 55px;
    }
}
