.AuthPage {
    font-size: 22px;
    text-align: center;
}

.AuthPage .AuthPage__label {
    background-color: #dddddd;
    width: 300px;
    padding: 20px;
    margin: 10px auto;
}

.AuthPage .AuthPage__input {
    position: absolute;
    top: calc(50vh - 188px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    text-security: disc;
    -webkit-text-security:disc;
    -moz-text-security:disc;
    margin: -70px 0px 0px 0px;
    width: 420px;
    max-width: 80vw;
    height: 60px;
    display: block;
    font-size: 22px;
    background-color: #eeeeee;
    padding: 0px;
    border: none;
    outline: none;
}

.AuthPage .AuthPage__button {
    font-family: 'Yrsa', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    position: absolute;
    top: calc(50vh - 188px);
    left: 50%;
    transform: translateX(-50%);
    width: 420px;
    max-width: 80vw;
    height: 60px;
    padding: 10px 0px;
    margin: 10px 0px 0px 0px;
    display: block;
    font-size: 22px;
    background-color: black;
    color: white;
    outline: none;
    cursor: pointer;
}

.AuthPage + .Footer {
    padding-top: calc(100vh - 188px - 100px);
}

@media (max-width: 980px) {
    .AuthPage .AuthPage__input, .AuthPage .AuthPage__button {
        width: 350px;
        height: 70px;
        font-size: 30px;
        line-height: 35px;
        /* width: 700px;
        height: 140px;
        font-size: 60px;
        line-height: 70px; */
    }

    .AuthPage .AuthPage__input {
        margin: -80px 0px 0px 0px;
    }
    .AuthPage .AuthPage__button {
        margin: 10px 0px;
    }
}
