.Header {
    font-size: 50px;
    line-height: 58px;
    padding: 0 20px 100px;
    text-align: center;
    /* max-width: 500px; */
    margin: 0 auto;
}

@media (max-width: 980px) {
    .Header {
        font-size: 50px;
        line-height: 55px;
    }
}
